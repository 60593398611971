import React, { useReducer, useCallback } from 'react'
import styled from 'styled-components'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Image from 'components/Image'
import Link from 'components/Link'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors[theme.primary].color};
  color: ${({ theme }) => theme.colors[theme.primary].contrast};
  .title {
    color: ${({ theme }) => theme.colors[theme.primary].highlight};
  }
  position: relative;
  overflow: hidden;
`
const Questions = styled.div`
  overflow: hidden;
  display: flex;
  width: ${({ length }) => length * 100}%;
  > * {
    width: ${({ length }) => 100 / length}%;
  }
`

const Question = styled.div`
  padding: 16px 80px 35px 80px;
`

const Answers = styled.div`
  position: absolute;
  padding: 16px 80px 35px 80px;
  right: 0;
  top: 0;
  display: flex;
  height: 100%;
  > a {
    flex: 1;
  }
`
const Answer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Left = styled(ChevronLeft)`
  position: absolute;
  font-size: 80px;
  left: 0;
  top: 0;
  height: 100%;
`

const Right = styled(ChevronRight)`
  position: absolute;
  font-size: 80px;
  right: 0;
  height: 100%;
  top: 0;
`

const DotContainer = styled.div``

const Dot = styled.div`
  border-radius: 15px;
  border 2px solid;
  width: 15px;
  height: 15px;
  margin:10px;`

const Dots = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  > ${DotContainer} {
    :nth-child(${({ current }) => current + 1}) {
      > ${Dot} {
        background-color: ${({ theme }) => theme.colors[theme.tertiary].color};
      }
    }
  }
`

export default function StoryblokQuestionnaire ({ data }) {
  const [answers, setAnswers] = useReducer(
    (state, { delta = 1, answer, idx }) => ({
      ...state,
      ...(answer && { [state.idx]: answer }),
      idx: idx === undefined ? Math.max(0, state.idx + delta) : idx
    }),
    { idx: 0 }
  )
  const next = useCallback(() => setAnswers({ delta: 1 }), [])
  const prev = useCallback(() => setAnswers({ delta: -1 }), [])
  const selectAnswer = useCallback(
    e => setAnswers({ answer: e.target.value }),
    []
  )

  const sums = data.questions.reduce(
    (acc, question, idx) =>
      question.answers
        .find(a => a._uid === answers[idx])
        ?.values.reduce(
          (a, value) => ({
            ...a,
            [value.name]: (a[value.name] || 0) + Number(value.value)
          }),
          acc
        ) || acc,
    {}
  )

  const totalSum = Object.values(sums).reduce((acc, value) => acc + value, 0)

  const results = Object.keys(sums)
    .map(key => ({
      key,
      sum: sums[key],
      percent: Math.round((100 * sums[key]) / totalSum),
      result: data.results.find(result => result.name === key)
    }))
    .sort((a, b) => b.sum - a.sum)

  return (
    <Container>
      <Questions
        length={data.questions.length + 1}
        style={{
          transform: `translateX(-${(100 * answers.idx)
            / (data.questions.length + 1)}%)`
        }}
      >
        {data.questions.map(question => (
          <Question>
            <div className="title">{question.question}</div>
            <RadioGroup onChange={selectAnswer} value={answers[answers.idx]}>
              {question.answers?.map(answer => (
                <FormControlLabel
                  key={answer._uid}
                  value={answer._uid}
                  control={<Radio />}
                  label={answer.answer}
                />
              ))}
            </RadioGroup>
          </Question>
        ))}
        <Answers>
          {results.slice(0, 3).map(({ result, percent }) => (
            <Link link={result.link}>
              <Answer key={result._uid}>
                <div className="title">
                  {result.title} ({percent}%)
                </div>
                <Image
                  flex
                  row
                  src={result.image}
                  alt={result.imageAlt}
                  description={result.imageDescription}
                  title={result.title}
                />
              </Answer>
            </Link>
          ))}
        </Answers>
      </Questions>
      {answers.idx > 0 && <Left onClick={prev} />}
      {answers.idx < data.questions.length && <Right onClick={next} />}
      {
        <Dots current={answers.idx}>
          {data.questions.concat([{ _uid: 'result' }]).map((question, idx) => (
            <DotContainer
              key={question._uid}
              onClick={() => setAnswers({ idx })}
            >
              <Dot />
            </DotContainer>
          ))}
        </Dots>
      }
    </Container>
  )
}
